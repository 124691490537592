import classNames from "classnames";
import PropTypes from "prop-types";
import { SiteLogo } from "../SiteLogos/SiteLogos";
import styles from "./Welcome.module.scss";

const Welcome = ({ title, text, children, customCssClass }) => {
  return (
    <section className={classNames(styles.container, customCssClass)}>
      <article className={classNames(styles.contentContainer, styles.textContainer)}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <p className={styles.text}>{text}</p>
      </article>
      <div className={styles.image}>
        <SiteLogo />
      </div>
      <article className={classNames(styles.contentContainer, styles.infoContainer)}>{children}</article>
    </section>
  );
};

Welcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  customCssClass: PropTypes.string,
};

export default Welcome;
