import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { HOS, myloc } from "../../../config/settings";
import { useTranslate } from "../../../language/i18n";
import { formatPostalNo } from "../../../utils/postalNo";
import { isAT, isHOS } from "../../../utils/siteEvaluator";
import { ExternalLink, Target, Type } from "../../shared/Link";
import styles from "./Footer.module.scss";

function Footer() {
  const userLoggedIn = !!useSelector(state => state.session.sessionId);

  return userLoggedIn ? (
    <footer className={styles.versionFooter}>
      <div className={styles.versionContent}>
        <div className={styles.version}>Version: {process.env.REACT_APP_VERSION}</div>
      </div>
    </footer>
  ) : (
    <footer className={styles.footer}>
      <div className={classNames(styles.content, styles.containerPadding)}>
        <Contact />
        <Links />
        <Address />
      </div>
    </footer>
  );
}

const Contact = () => {
  const translate = useTranslate();
  return (
    <div>
      <Title>{translate("CONTACT")}</Title>
      {isHOS() ? (
        <ExternalLink url={HOS.contactPhone} type={Type.PHONE} customCssClass={styles.text}>
          {translate("PHONE_SHORT")}: {HOS.contactPhone.toString}
        </ExternalLink>
      ) : isAT() ? (
        <ExternalLink url={myloc.contactPhone} type={Type.PHONE} customCssClass={styles.text}>
          {translate("PHONE_SHORT")}: {myloc.contactPhone.toString}
        </ExternalLink>
      ) : (
        <></>
      )}
    </div>
  );
};

const Links = () => {
  const translate = useTranslate();
  return (
    <div className={classNames(styles.section)}>
      <Title>{translate("SUPPORT")}</Title>
      {isHOS() ? (
        <>
          <ExternalLink url={HOS.supportEmail} type={Type.EMAIL} customCssClass={classNames(styles.text)}>
            {isHOS() ? HOS.supportEmail.toString : isAT() ? myloc.supportEmail.toString : ""}
          </ExternalLink>
          <ExternalLink url={myloc.cookieIntegrityUrl} target={Target.NEW_TAB} customCssClass={styles.text}>
            {translate("COOKIE_POLICY")}
          </ExternalLink>
        </>
      ) : isAT() ? (
        <>
          <ExternalLink url={myloc.supportEmail} type={Type.EMAIL} customCssClass={classNames(styles.text)}>
            {myloc.supportEmail.toString}
          </ExternalLink>
          <ExternalLink url={myloc.cookieIntegrityUrl} target={Target.NEW_TAB} customCssClass={styles.text}>
            {translate("COOKIE_POLICY")}
          </ExternalLink>
        </>
      ) : (
        <></>
      )}
      <Version />
    </div>
  );
};

const Version = () => {
  return (
    <div>
      <Text>Version: {process.env.REACT_APP_VERSION}</Text>
    </div>
  );
};
const Address = () => {
  const translate = useTranslate();
  return (
    <div className={styles.address}>
      <Title>{translate("ADDRESS")}</Title>
      {isHOS() ? (
        <>
          <Text>{HOS.address.street}</Text>
          <Text>{`${formatPostalNo(HOS.address.postalNo)} ${HOS.address.city}`}</Text>
          <Text>{HOS.address.county}</Text>
        </>
      ) : isAT() ? (
        <>
          <Text>{myloc.address.street}</Text>
          <Text>{`${formatPostalNo(myloc.address.postalNo)} ${myloc.address.city}`}</Text>
          <Text>{myloc.address.county}</Text>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const Title = ({ children }) => <p className={styles.title}>{children}</p>;
const Text = ({ children }) => <p className={styles.text}>{children}</p>;

Footer.propTypes = {
  session: PropTypes.object,
  user: PropTypes.object,
};

Text.propTypes = {
  children: PropTypes.node,
};

Title.propTypes = {
  children: PropTypes.node,
};

export default Footer;
