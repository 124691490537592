export enum Site {
  HUL = "HUL",
  AT = "AT",
  HOS = "HOS",
}

export const isHUL = () => getSite() === Site.HUL;

export const isHOS = () => getSite() === Site.HOS;

export const isAT = () => getSite() === Site.AT;

export const getSite = (): Site | null => {
  const domainName = window.location.hostname.toLowerCase();

  if (domainName.includes("hul")) {
    return Site.HUL;
  }

  if (domainName.includes("at")) {
    return Site.AT;
  }

  //To eval hös, evaluation is translated into punycode: hös = xn--hs-fka
  if (domainName.includes("hos") || domainName.includes("xn--hs-fka")) {
    return Site.HOS;
  }

  return null;
};
