import platform from "platform";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import { useBankIDCollect, useBankIdStartAuthentication, useQRCode } from "./useBankIdAuthentication";

export const UNKNOWN = "unknown";

export const detectBrowser = () => {
  if (!platform.os) return UNKNOWN; //

  const browser = platform.name;

  if (browser?.startsWith("Firefox")) {
    return "firefox";
  } else if (browser === "Edge") {
    return "edge";
  } else if (browser?.startsWith("Chrome")) {
    return "googlechrome";
  }

  return UNKNOWN; // Fallback case
};

export const useBankId = () => {
  const [bankIdError, setBankIdError] = useState<string | undefined>(undefined);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const [bankIdUrl, setBankIdUrl] = useState<string | undefined>(undefined);

  const { autoStartToken, handleAuth, bankIdContent, resetAuth, resetBankIdContent } = useBankIdStartAuthentication(
    setBankIdError,
  );

  const { qrCodeContent, stopQRGeneration } = useQRCode(bankIdContent);
  const { collectedResponse, resetCollect } = useBankIDCollect(bankIdContent, setBankIdError);

  const sessionId = useSelector((state: RootState) => state.session.sessionId);

  const restart = () => {
    reset();
    resetAuth();
  };

  const reset = useCallback(() => {
    resetBankIdContent();
    resetCollect();
    //resetAuth();
    stopQRGeneration;
    setCancelled(false);
  }, [resetBankIdContent, resetCollect, stopQRGeneration]);

  const resetBankIdUrl = () => {
    setBankIdUrl(undefined);
  };

  useEffect(() => {
    if (sessionId) {
      setCancelled(true);
    }
  }, [sessionId]);

  useEffect(() => {
    if (bankIdError) {
      reset();
    }
  }, [bankIdError, reset]);

  useEffect(() => {
    if (!autoStartToken) return;

    /* Testa att se om popup försvinner
    setBankIdUrl(
      "https://app.bankid.com/authenticate?autostarttoken=" +
        autoStartToken +
        (detectBrowser() === "unknown" ? "" : "&redirect=" + detectBrowser() + "%3A%2F%2F"),
    );
    */

    setBankIdUrl(
      "bankid:///?autostarttoken=" +
        autoStartToken +
        (detectBrowser() === "unknown" ? "" : "&redirect=" + detectBrowser() + "%3A%2F%2F"),
    );
  }, [autoStartToken]);

  useEffect(() => {
    if (!collectedResponse) return;

    if (collectedResponse.hintCode === "userSign" || collectedResponse.status !== "pending") {
      stopQRGeneration();
    }
  }, [collectedResponse, stopQRGeneration]);

  const initAuth = () => {
    if (bankIdError) {
      setBankIdError(undefined);
      //setCancelled(true);
    }

    setBankIdUrl(undefined);
    handleAuth();
  };

  const isBankIdError = () => !!bankIdError;

  return {
    autoStartToken,
    bankIdContent,
    bankIdUrl,
    cancelled,
    collectedResponse,
    initAuth,
    isBankIdError,
    qrCodeContent,
    reset,
    resetBankIdUrl,
    restart,
  };
};
