import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useFetchSessionData from "../hooks/useFetchSessionData";
import useIsSingleSignOn from "../hooks/useIsSingleSignOn";

import useComflowLogin from "../components/Comflow/hooks/useComflowLogin";
import { setCompany, setFacility, setInformation, setOrder } from "../reducers/appData/appDataActions";
import { useAppDispatch } from "../reducers/hooks/useAppDispatch";
import {
  setApplicationsList,
  setAuthorizationList,
  setInitialized,
  setLoginToken,
  setRememberMe,
  setSessionId,
  setUserHasMessageToAccept,
} from "../reducers/session/sessionAction";
import {
  selectHasComflowAccess,
  selectHasFSPAccess,
  selectUserHasMessageToAccept,
} from "../reducers/session/sessionSelectors";

import { REMEMBER_ME, SESSION_ID } from "../reducers/session/sessionVariables";
import accountService from "../services/account/accountService";
import storage from "../utils/store/storage";

const Init = () => {
  const refLoginToken = useRef();
  const [stateLoginToken, setStateLoginToken] = useState();
  const isSingleSignOn = useIsSingleSignOn();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useSelector(state => state.session?.initialized);
  const applicationsList = useSelector(state => state.session.applicationsList);
  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const hasComflowAccess = useSelector(selectHasComflowAccess);
  const userHasMessageToAccept = useSelector(selectUserHasMessageToAccept);

  const { initiateLogin, isRedirectToComflowAllowed, isLoggedIn } = useComflowLogin();

  //Check if only authorized to Comflow, redirect if so
  useEffect(() => {
    if (!isRedirectToComflowAllowed() || !applicationsList || userHasMessageToAccept === undefined) return;

    const handleRedirection = async () => {
      //Need to manually delete sessionId from storage to avaio login loop if returned from Comflow
      //Cannot use logout, since we will lose the session before(?) redirecting
      //Next time user returns login screen will be shown
      await storage.deleteItem(SESSION_ID);

      window.open(process.env.REACT_APP_COMFLOW_WEBAPP, "_self");
    };

    if (!hasFSPAccess && hasComflowAccess && userHasMessageToAccept === null) {
      if (isLoggedIn) {
        handleRedirection();
      } else {
        initiateLogin();
      }
    }
  }, [
    applicationsList,
    hasComflowAccess,
    hasFSPAccess,
    initiateLogin,
    isLoggedIn,
    isRedirectToComflowAllowed,
    userHasMessageToAccept,
  ]);

  const dispatch = useAppDispatch();

  const initializeSingleSignOnSession = useCallback(async () => {
    setRememberMe(false);

    //Doesn't need a session, is created on the fly from mod_auth_openidc in reversed proxy
    await loadSessionData();
  }, [loadSessionData]);

  const querystringToken = queryString.parse(location.search).loginToken;

  useEffect(() => {
    if (!!querystringToken && querystringToken !== stateLoginToken) {
      setStateLoginToken(querystringToken);
      removeLoginTokenFromQueryString();
    }
  }, [querystringToken, stateLoginToken]);

  const initializeSingleSignOnAndStandard = useCallback(async () => {
    await initializeSingleSignOnSession();
    await initializeStandardLoginSession(dispatch);
  }, [dispatch, initializeSingleSignOnSession]);

  const doInit = useCallback(async () => {
    if (isSingleSignOn && !sessionInitialized) {
      initializeSingleSignOnAndStandard();
    } else if (!isSingleSignOn && isMylocLogin()) {
      initializeMylocLoginSession(dispatch, refLoginToken);
    } else if (!isSingleSignOn && !!stateLoginToken && stateLoginToken !== refLoginToken.current) {
      await doLoginWithSessionToken(dispatch, stateLoginToken, refLoginToken);
    } else if (!isSingleSignOn && !sessionInitialized) {
      initializeStandardLoginSession(dispatch);
    }
  }, [dispatch, initializeSingleSignOnAndStandard, isSingleSignOn, sessionInitialized, stateLoginToken]);

  useEffect(() => {
    if (isSingleSignOn === undefined) {
      return;
    }

    doInit();
  }, [doInit, isSingleSignOn]);

  return null;
};

const initSessionData = (data, dispatch) => {
  setCompany({
    id: data.company?.id,
    description: data.company?.label,
  });

  setFacility({
    id: data?.facility?.id,
    description: data?.facility?.label,
    value: data?.facility?.value,
    category: data?.facility?.category,
  });

  if (data.user) setInformation(data.user);

  if (data.order) setOrder(data.order);

  // Session

  setSessionId(data.id);

  setAuthorizationList(data.authorizations);

  dispatch(setApplicationsList(data.applications));
  setUserHasMessageToAccept(data.accessMessage);

  setInitialized();
};

const doLoginWithSessionToken = async (dispatch, stateLoginToken, refLoginToken) => {
  const data = {
    token: stateLoginToken,
  };
  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithMylocToken = async (mylocLoginToken, dispatch, refLoginToken) => {
  const data = {
    mylocLoginToken: mylocLoginToken,
    persistent: false,
  };

  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithToken = async (data, dispatch, refLoginToken) => {
  const token = data?.token ? data?.token : data?.mylocLoginToken;
  if (refLoginToken.current === token) return;

  refLoginToken.current = token;

  const response = await accountService.loginWithToken(data);
  if (response.data?.id) {
    const appData = await accountService.getAppData();

    initSessionData(appData.data, dispatch);
  }
};

const mylocAuthentication = async (mylocLoginToken, dispatch, refLoginToken) => {
  if (!mylocLoginToken) return;
  setLoginToken(mylocLoginToken, false);
  await doLoginWithMylocToken(mylocLoginToken, dispatch, refLoginToken);
};
const isMylocLogin = () => {
  return getMylocLoginToken() !== undefined;
};
const getMylocLoginToken = () => {
  return queryString.parse(location.search).mylocLoginToken;
};

/*
const isSessionTokenLogin = () => {
  return getSessionLoginToken() !== undefined;
};
*/

const removeLoginTokenFromQueryString = () => {
  const url = new URL(window.location.href);

  // Remove the 'loginToken' parameter
  url.searchParams.delete("loginToken");

  // Update the browser's URL without reloading the page
  window.history.replaceState({}, "", url.toString());
};

/*
async function initializeSessionTokenLogin(dispatch, stateLoginToken, refLoginToken) {
  await doLoginWithSessionToken(dispatch, refLoginToken);
}
  */

async function initializeMylocLoginSession(dispatch, refLoginToken) {
  let query = queryString.parse(location.search);
  //If a virtualSessionId exists, use it instead of logging in again
  if (query.virtualSessionId) {
    setSessionId(encodeURIComponent(query.virtualSessionId));
    const response = await accountService.getAppData();
    setAuthorizationList(response.data.authorizations);
    setInitialized();
  } else {
    await mylocAuthentication(query.mylocLoginToken, dispatch, refLoginToken);
  }
  return;
}

async function initializeStandardLoginSession(dispatch) {
  let rememberMe = await storage.loadItem(REMEMBER_ME);
  setRememberMe(!!rememberMe);

  const sessionId = await storage.loadItem(SESSION_ID);
  if (sessionId) {
    const response = await accountService.getAppData();
    if (response.isOk()) {
      initSessionData(response.data, dispatch);
    } else {
      storage.saveItem(SESSION_ID, null, rememberMe);
    }
  }

  setInitialized();
}

export default Init;
