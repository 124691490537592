import classNames from "classnames";
import { default as hosLogo } from "../../../assets/logos/HÖS/HÖS.jpg";
import { default as mylocLogo } from "../../../assets/logos/Myloc-logga.svg";
import { default as hulLogo } from "../../../assets/logos/Uppsala_logo.png";
import { getSite, Site } from "../../../utils/siteEvaluator";
import Image from "../../shared/Image/Image";
import HÖSMunicipalityLogo from "../../shared/MunicipalityLogo/HÖSMunicipalityLogo";
import MunicipalityLogo from "../../shared/MunicipalityLogo/MunicipalityLogo";
import styles from "./CompanyLogoList.module.scss";

export const SiteLogo = () => {
  const site = getSite();
  switch (site) {
    case Site.HUL:
      return <Image src={hulLogo} alt="Uppsala - Logotyp" customCssClass={styles.imageContainer} />;
    case Site.HOS:
      return <Image src={hosLogo} alt="Hjälpmedelscentralen - Logo" customCssClass={styles.imageContainer} />;
    default:
      return <img src={mylocLogo} alt="Myloc logo" className={styles.logo} />;
  }
};
export const CompanyLogoList = ({ customCssClass }) => {
  const HOSCompanyLogoList = () => {
    return (
      <section className={classNames(styles.list, customCssClass)}>
        <HÖSMunicipalityLogo id="Kristianstad" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Bromölla" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Hässleholm" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Hörby" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Osby" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Simrishamn" customCssClass={styles.specialLogo} />
        <HÖSMunicipalityLogo id="ÖstraGöinge" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Tomelilla" customCssClass={styles.logoNoWidth} />
        <HÖSMunicipalityLogo id="Ystad" customCssClass={styles.logo} />
        <HÖSMunicipalityLogo id="Sjöbo" customCssClass={styles.specialLogo} />
        <HÖSMunicipalityLogo id="Skurup" customCssClass={styles.specialLogo} />
      </section>
    );
  };

  const HULCompanyLogoList = ({ customCssClass }) => (
    <section className={classNames(styles.list, customCssClass)}>
      <MunicipalityLogo id="Heby" customCssClass={styles.logo} />
      <MunicipalityLogo id="Habo" customCssClass={styles.logo} />
      <MunicipalityLogo id="Enkoping" customCssClass={styles.logo} />
      <MunicipalityLogo id="Osthammar" customCssClass={styles.logo} />
      <MunicipalityLogo id="Tierp" customCssClass={styles.logo} />
      <MunicipalityLogo id="Knivsta" customCssClass={styles.logo} />
    </section>
  );

  const CompanyLogoListSelector = () => {
    const site = getSite();

    switch (site) {
      case Site.HUL:
        return <HULCompanyLogoList customCssClass={styles.companyLogoSegment} />;
      case Site.HOS:
        return <HOSCompanyLogoList customCssClass={styles.companyLogoSegment} />;
      default:
        return null;
    }
  };

  return <CompanyLogoListSelector />;
};
